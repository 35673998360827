const fixedmenu = () => {
  const fixedMenuClassName = 'fixedmenu'

  const setMenuPadding = (fixedMenu, isBottomMenu) => {
    const menuHeight = `${fixedMenu.offsetHeight}px`;
    const klickartSections = document.querySelector('[klickart-sections]')
    klickartSections.style.transition = "padding-top 1s"

    if(isBottomMenu) {
      klickartSections.style.paddingBlockEnd = menuHeight
      return
    }
    klickartSections.style.paddingTop = menuHeight;
  }

  const topPadding = (fixedMenu, isBottomMenu) => {

    fixedMenu.style.paddingLeft = '0px'
    if (isBottomMenu) {
      fixedMenu.style.top = ''
      return
    }

    fixedMenu.style.top = '0px'
  }

  const fixedMenus = document.querySelectorAll(`.${fixedMenuClassName}`)

  fixedMenus.forEach((fixedMenu) => {
    const isBottomMenu = fixedMenu.classList.contains('bottom')
    topPadding(fixedMenu, isBottomMenu)
    setMenuPadding(fixedMenu, isBottomMenu)
  })
}



export default fixedmenu
